<template>
  <div>
    <Dropdown
      v-if="subOptions && subOptions?.length > 0"
      id="sidebar-dropdown"
      class="w-full"
      :close-on-outside-click="false"
      :options-display-type="DropdownOptionsDisplayType.INLINE"
      :opened-by-default="isSubOptionRoute"
    >
      <template #activator="{ toggleDropdownVisibility, isOpened }">
        <button
          class="flex flex-col rounded-lg w-full px-2 text-on-surface leading-none font-semibold cursor-pointer"
          @click="toggleDropdownVisibility"
        >
          <div class="flex items-center justify-between h-12 w-full">
            <div
              class="flex items-center"
              :class="{ 'text-primary': isOptionPathActive(to) }"
            >
              <Icon :icon="icon" class="mx-2" />{{ label }}
            </div>
            <Icon
              v-if="subOptions"
              :icon="ArrowDownSvg"
              :class="{ 'transition-all rotate-180': isOpened }"
            />
          </div>
        </button>
      </template>
      <template #default>
        <div
          v-if="subOptions"
          class="flex flex-col items-start gap-6 text-sm pl-4 border-l ml-7 pt-3 text-on-surface font-semibold"
        >
          <NuxtLink
            v-for="(subOption, subOptionIndex) in subOptions"
            :key="subOptionIndex"
            :to="subOption.to"
            :class="{ 'text-primary': isOptionPathActive(subOption.to) }"
            @click.prevent
          >
            {{ subOption.label }}
          </NuxtLink>
        </div>
      </template>
    </Dropdown>
    <NuxtLink
      v-else
      :to="to"
      class="flex flex-col rounded-lg w-full px-2 text-on-surface leading-none font-semibold cursor-pointer"
    >
      <div class="flex items-center justify-between h-12 w-full">
        <div
          class="flex items-center"
          :class="{ 'text-primary': isOptionPathActive(to) }"
        >
          <Icon :icon="icon" class="mx-2" />{{ label }}
        </div>
        <Icon
          v-if="subOptions"
          :icon="ArrowDownSvg"
          :class="{ 'transition-all rotate-180': isOpen }"
        />
      </div>
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import { Icon } from '~/components/icon';
import ArrowDownSvg from '~/assets/icons/arrow-down.svg?component';
import { useRoute } from 'vue-router';
import { Dropdown } from '~/components/dropdown';
import { DropdownOptionsDisplayType } from '~/components/dropdown/dropdown.constants';

type SidebarOption = {
  label: string;
  icon?: object;
  to?: string;
  subOptions?: SidebarOption[];
};

const props = defineProps<SidebarOption>();
const route = useRoute();
const isOpen = ref<boolean>(false);

const isOptionPathActive = (path?: string) => {
  const formattedOptionPath = path?.replace('/', '') || '';
  const formattedRoutePath = route.path.replace('/', '');
  const isDashboardPath = path === '/';

  if (isDashboardPath) {
    return route.path === path;
  }

  return formattedOptionPath.length > 0
    ? formattedRoutePath.includes(formattedOptionPath)
    : false;
};

const isSubOptionRoute = computed(() =>
  Boolean(
    props.subOptions?.some((subOption) =>
      subOption.to ? route.path.includes(subOption.to) : false,
    ),
  ),
);
</script>
